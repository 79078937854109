<template>
  <div class="backgroundimage">
    <form @submit.prevent="handleLogin" class="content">
      <ModalInformation :title="'Success!'" :message="'Press okay to continue'" :buttonTitle="'Okay'" :show="showModalInformation" v-on:handleClose="handleClose"/>
      <div class="header">
        <div class="d-flex align-items-center" style="width: 100%">
          <!-- <img src="@/assets/images/baground.png" alt="bg-login"/> -->
          <!-- <div class="back-button feedback" @click="goBack">
            <img src="@/assets/images/icons8-chevron-left-90.png" alt="Back">
          </div> -->
          <div style="width: 100%">
            <div class="text-login">
              Log In
            </div>
          </div>
        </div>
        <div class="wrapper-email mt-4 my-3">
          <div :class="[emailPlaceholder ? 'top' : 'center', 'placeholder']">
            Email
          </div>
          <input class="email" type="text" v-model="email" @click="hanclePlaceholderEmail" @blur="hanclePlaceholderEmailBlur" @focus="hanclePlaceholderEmailFocus">
          <p class="text-danger" v-if="email.length >=1 && !email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)">email must be a valid email</p>
        </div>
        <div class="wrapper-password">
          <div :class="[passwordPlaceholder ? 'top' : 'center', 'placeholder']">
            Password
          </div>
          <input class="password" type="password" v-model="password" @click="hanclePlaceholderPassword" @blur="hanclePlaceholderPasswordBlur" @focus="hanclePlaceholderPasswordFocus" id="show">
          <div class="wrapper-eye-icon">
            <img src="@/assets/images/closed-eye.png" alt="Close Eye" @click="handleShowPassword" v-if="closeEye">
            <img src="@/assets/images/open-eye.png" alt="Open Eye" v-else  @click="handleShowPassword">
          </div>
          <p class="text-danger" v-if="password.length >=1 && !password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,20}$/)">password should be combination of one uppercase , one lower case, one number and min 8, max 20 char long</p>
        </div>
        <div class="d-flex justify-content-between">
          <div class="forget-password" @click="pushTo('ForgotPassword')">Forgot Password?</div>
          <div class="dont-have-account" @click="pushTo('Register')">Don't have Account? Sign Up</div>
        </div>
    </div>
        <div class="d-flex justify-content-center">
          <img src="../../assets/images/robopark.png" class="logo" fluid/>
        </div>
      <div class="d-flex justify-content-center">
        <!-- <div class="term-condition">By continuing, you agree to our <span class="text-purple">Terms of Service</span> and <span class="text-purple">Privacy Policy.</span></div> -->
        <button class="button mt-5 mb-5" @click="handleLogin">
          Log In
        </button>
      </div>
    </form>
  </div>

</template>

<script>
import ModalInformation from '../../components/ModalInformation.vue'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'
import showPass from '../../mixins/showPassword'

export default {
  name: 'LoginEmail',
  components: {
    ModalInformation
  },
  mixins: [showPass],
  data: () => {
    return {
      emailPlaceholder: false,
      passwordPlaceholder: false,
      email: '',
      password: '',
      showModalInformation: false,
      closeEye: true
    }
  },
  mounted () {
    this.firstRender()
  },
  methods: {
    ...mapActions(['login']),
    handleShowPassword () {
      this.showPassword()
      this.closeEye = !this.closeEye
    },
    pushTo (routeName) {
      this.$router.push({ name: routeName })
    },
    firstRender () {
      console.log('Hello World')
    },
    hanclePlaceholderEmail () {
      this.emailPlaceholder = true
    },
    hanclePlaceholderEmailBlur () {
      if (!this.email) {
        this.emailPlaceholder = false
      }
    },
    hanclePlaceholderEmailFocus () {
      this.emailPlaceholder = true
    },
    hanclePlaceholderPassword () {
      this.passwordPlaceholder = true
    },
    hanclePlaceholderPasswordBlur () {
      if (!this.password) {
        this.passwordPlaceholder = false
      }
    },
    hanclePlaceholderPasswordFocus () {
      this.passwordPlaceholder = true
    },
    async handleLogin () {
      if (this.email.length >= 1 && !this.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) || this.password.length >= 1 && !this.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!#%*?&]{8,20}$/)) {

      } else {
        if (this.email.length < 1 || this.password.length < 1) {

        } else {
          const payload = {
            email: this.email,
            password: this.password
          }
          try {
            const res = await this.login(payload)
            if (res.dataCustomer.is_verified) {
              // Jika akun nya sudah terverifikasi
              this.showModalInformation = true
            } else {
              // Jika akun blm terverifikasi, hapus dulu localstoragenya
              localStorage.clear()
              this.$router.push({ name: 'RegisterVerificationEmail', params: { session: res.token } })
            }
          } catch (error) {
            console.log('error', error)
            Swal.fire(
              error,
              '',
              'error'
            )
          }
        }
      }
    },
    handleClose () {
      this.showModalInformation = false
      this.$router.push({ path: '/main/account' })
    },
    goBack () {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.backgroundimage{
  max-width: 100%;
  background-image: url(../../assets/images/tesbg3.jpg);
  background-size:cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items:center;
}
.header{
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 max-width: 100%;
}
.logo{
  width: 70%;
  margin-top: 5px;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.feedback{
  cursor: pointer;
}
.feedback:hover{
  transform: scale(1.01);
}

.content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95vh;
  margin: 2px;
}
.back-button{
  margin: 20px 0;
}
.back-button{
  width: 20px;
  height: 20px;
}
.back-button > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.option-country-code{
  padding: 3px 5px;
}
input.email, input.password{
  width: 100%;
  border-radius: 5px;
  border: 1px solid #BBBBBB;
  margin-left: 1px;
  /* padding: 8px 8px; */
  text-justify: auto;
  height: 50px;
  padding-top: 8px;
  padding-left: 7px;
}
input.email:focus, input.password:focus , .option-country-code:focus{
  outline: 0.3px #6B4EFF solid;
}
.desc-muted{
  margin-top: 10px;
  font-size: .8rem;
}
.buttonhead{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.button{
  background-color: #6B4EFF;
  width: 50%;
  border-radius: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 0;
}
.button-email{
  text-align: center;
  margin: 20px 0;
  color: #6B4EFF;
}
.text-login{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color:white;
  font-size: 1.8rem;
  font-weight: 600;
}
.wrapper-email, .wrapper-password{
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 5px;
}
.placeholder{
  position: absolute;
  color: #757575;
}
.center{
  left: 7px;
  top: 11px;
  transition: 0.2s;
}
.top{
  font-size: 0.75rem;
  left: 7px;
  top: 2px;
  transition: 0.2s;
}
.forget-password, .dont-have-account{
  color: white;
  margin-top: 15px;
  padding: 5px;
  font-size: .9rem;
  font-weight: 400;
  cursor: pointer;
}
.forget-password:hover, .dont-have-account:hover{
  transform: scale(1.01);
}
.text-purple{
  color: #6B4EFF;
}
.term-condition {
  font-size: 0.8rem;
  width: 90%
}
.text-danger{
  line-height: 150%
}
.button{
  border: none;
  width: 40%;
}
.wrapper-eye-icon{
  width: 25px;
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
}
.wrapper-eye-icon > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
@media screen and (max-width: 495px) {
  .wrapper-eye-icon{
    width: 22px;
  }
  .forget-password, .dont-have-account{
  color: white;
  margin-top: 15px;
  padding: 5px;
  font-size: .8rem;
  font-weight: 400;
  cursor: pointer;
  }
  .logo{
  width: 70%;
  display: flex;
  justify-content: center;
  }
}
</style>
